import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Content, { HTMLContent } from '../components/Content';
import Layout from '../components/Layout';

export const MusicPageTemplate = ({
  title,
  content,
  contentComponent,
  releases = [],
}) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="flex flex-column">
      <h1 className="title thearcher">{title}</h1>
      <PageContent className="content" content={content} />
      <div className="flex flex-wrap">
        {releases.map(({ node }) => (
          <Link
            className="pl2 text-color pr2 pt2 link dim w-100-m"
            key={node.frontmatter.releaseTitle}
            to={node.fields.slug}
            title={node.frontmatter.releaseTitle}
          >
            <div className="flex flex-column w-100">
              {node.frontmatter.image && (
                <img
                  className="w-100"
                  alt={node.frontmatter.releaseTitle}
                  src={node.frontmatter.image.childImageSharp.fluid.src}
                />
              )}
              <div className="pv2 flex flex-row">
                <span className="flex-auto b">
                  {node.frontmatter.releaseTitle}
                </span>
                <span>{node.frontmatter.date}</span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

MusicPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  releases: PropTypes.arrayOf(PropTypes.object),
  contentComponent: PropTypes.func,
};

const MusicPage = ({ data }) => {
  const { markdownRemark: post, allMarkdownRemark: releases } = data;

  return (
    <Layout>
      <MusicPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        releases={releases && releases.edges}
        content={post.html}
      />
    </Layout>
  );
};

MusicPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MusicPage;

export const MusicPageQuery = graphql`
  query MusicPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "release-post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  src
                }
              }
            }
            releaseTitle
          }
        }
      }
    }
  }
`;
